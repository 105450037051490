<template>
    <v-container fill-height>
        <v-layout wrap justify-center>
            <v-flex md12>
                <material-card title="Sales Departments"
                               text="Sales departments for customer organisations">

                    <v-alert type="info" v-show="!user.profile_user.department">
                        You're currently set not to use a department, as such you will
                        not be able to check out any orders.
                    </v-alert>

                    <v-checkbox
                            v-show="user.profile_user.department"
                            @change="set_cus_store_storage()"
                            v-model="customer_store"
                            :label="'Show Customer Only Products'"
                    ></v-checkbox>


                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th class="text-left">Organisation Name</th>
                            <th class="text-left">Department Name</th>
                            <th class="text-left">Buyer Name</th>
                            <th class="text-left">Buyer Email</th>
                            <th class="text-left">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in departments" :key="index">
                            <td>
                                {{ item.organisation.name }}
                            </td>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.buyer.first_name }} {{ item.buyer.last_name }}
                            </td>
                            <td>
                                {{ item.buyer.email }}
                            </td>
                            <td>
                                <v-btn
                                        v-if="user.profile_user.department !== item.id"
                                        color="blue-grey darken-4"
                                        fab
                                        text
                                        @click="use_as_department(item)"
                                >
                                    <v-icon >
                                        mdi-check
                                    </v-icon>

                                </v-btn>

                                <v-icon v-if="user.profile_user.department === item.id">
                                    mdi-book-multiple
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<style lang="scss">

</style>

<script>

    export default {
        components: {},
        data() {
            return {
                departments: [],
                user: {},
                customer_store: false
            }
        },
        created() {
            this.user = JSON.parse(localStorage.getItem('user'));
            console.log(this.user);
            this.customer_store = !!(localStorage.getItem('customer_store') && localStorage.getItem('customer_store') === 'true');
            this.load_organisations();
        },
        methods: {
            set_cus_store_storage()
            {

                localStorage.removeItem('customer_store_dep');
                localStorage.removeItem('customer_store');
                localStorage.setItem('customer_store_dep', this.user.profile_user.department);
                localStorage.setItem('customer_store', this.customer_store);
            },
            use_as_department(dep) {
                this.$http.patch(
                    this.$http.defaults.baseURL + '/user/profile/' + this.user.profile_user.id, {
                        department: dep.id
                    }).then((response) => {
                    console.log(response);

                    this.notification = true;
                    this.notification_message = 'Successfully using department: ' + dep.name;
                    this.set_cus_store_storage();
                    this.user.profile_user.department = dep.id;

                    let save_storage_user = JSON.stringify(this.user);
                    localStorage.removeItem('user');
                    localStorage.setItem('user', save_storage_user);

                    this.update_cart_items();
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            update_cart_items()
            {
                this.$hget(
                    this.$http.defaults.baseURL + '/sales/update-prices-dep-switch/').then((response) => {
                        if(typeof response.data !== 'undefined')
                        {
                            console.log("Success Change");
                        }
                    });
            },
            load_organisations() {
                this.$http.get(
                    this.$http.defaults.baseURL + '/sales/departments/?limit=1000').then((response) => {
                    console.log(response);
                    this.departments = response.data.results;
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            }
        }
    }
</script>
